import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ContactStyles from "../components/contact.module.css"

const Contact = () => (
  <Layout>
    <SEO title="Contact" />
    <h1>Contact RekaS</h1>
    <p>If there's something you would like to tell me, please do so below. I'll try to get back to you as soon as I can. In all honesty, you'll probs reach me faster on my <a href="https://www.instagram.com/madebyrekas/" target={'_blank'} rel={'noopener noreferrer'}>Instagram</a>.</p>

    <form name="contact_form" method="POST" data-netlify="true" action="/thank-you" className={ContactStyles.form}>
      <label className={ContactStyles.item} for="name">Your Name:</label><input type="text" name="name" className={ContactStyles.input}/>
      <label className={ContactStyles.item} for="email">Your Email:</label><input type="email" name="email" className={ContactStyles.input}/>
      <label className={ContactStyles.item} for="message">Message:</label><textarea name="message" className={ContactStyles.input} rows='8'/>
      <button className={ContactStyles.submitButton} type="submit">Send</button>
    </form>

  </Layout>
)

export default Contact
